var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.close,
      "initial-focus-ref": function initialFocusRef() {},
      "is-centered": "",
      "size": _vm.size ? _vm.size : ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'sm' : 'md',
      "close-on-overlay-click": _vm.closeOnOverlayClick
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "border-radius": ['8px', '16px'],
      "margin": "auto"
    }
  }, [_vm.hasHeader ? _c('c-modal-header', {
    attrs: {
      "padding": "0"
    }
  }, [_vm._t("header", function () {
    return [_c('c-box', {
      attrs: {
        "display": "flex",
        "justify-content": "center",
        "margin": "24px 0 0 0"
      }
    }, [_c('CImage', {
      attrs: {
        "size": "150px",
        "object-fit": "cover",
        "src": require('@/assets/images/illustration-130323-raw.jpeg'),
        "alt": "Image Confirmation"
      }
    })], 1)];
  })], 2) : _vm._e(), _vm.withButtonClose ? _c('c-modal-close-button', {
    attrs: {
      "size": "sm",
      "color": "white",
      "border-radius": "100%",
      "background-color": "danger.400",
      "_hover": {
        backgroundColor: 'danger.500'
      },
      "_active": {
        backgroundColor: 'danger.600'
      },
      "_focus": {
        boxShadow: 'outline'
      }
    }
  }) : _vm._e(), _vm.hasBody ? _c('c-modal-body', {
    attrs: {
      "padding": "0"
    }
  }, [_vm._t("body", function () {
    return [_c('c-box', {
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "px": "24px",
        "py": "16px",
        "text-align": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "700",
        "font-size": ['18px', '20px'],
        "mb": "16px"
      }
    }, [_vm._v(" Program Tidak Dapat Dibeli ")]), _c('c-text', {
      attrs: {
        "font-weight": "400",
        "font-size": ['14px', '16px'],
        "color": "#555555"
      }
    }, [_vm._v(" Kamu masih memiliki program aktif lainnya. Silahkan menunggu hingga program selesai atau lakukan pembelian dengan jenis program yang sama. ")])], 1)];
  })], 2) : _vm._e(), _vm.hasFooter ? _c('c-modal-footer', {
    attrs: {
      "padding": "0"
    }
  }, [_vm._t("footer", function () {
    return [_c('c-box', {
      attrs: {
        "width": "100%",
        "px": "24px",
        "mb": "24px"
      }
    }, [_c('BaseButton', {
      attrs: {
        "color": "primary",
        "rounded": "1000px",
        "width": "100%"
      },
      on: {
        "click": _vm.close
      }
    }, [_vm._v(" Kembali ")])], 1)];
  })], 2) : _vm._e()], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }