<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :initial-focus-ref="() => {}"
    is-centered
    :size="size ? size : ['sm', 'md'].includes(currentBreakpoint) ? 'sm' : 'md'"
    :close-on-overlay-click="closeOnOverlayClick"
  >
    <c-modal-content
      ref="content"
      :border-radius="['8px', '16px']"
      margin="auto"
    >
      <c-modal-header
        v-if="hasHeader"
        padding="0"
      >
        <slot name="header">
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <CImage
              size="150px"
              object-fit="cover"
              :src="require('@/assets/images/illustration-130323-raw.jpeg')"
              alt="Image Confirmation"
            />
          </c-box>
        </slot>
      </c-modal-header>
      <c-modal-close-button
        v-if="withButtonClose"
        size="sm"
        color="white"
        border-radius="100%"
        background-color="danger.400"
        :_hover="{ backgroundColor: 'danger.500' }"
        :_active="{ backgroundColor: 'danger.600' }"
        :_focus="{ boxShadow: 'outline' }"
      />
      <c-modal-body
        v-if="hasBody"
        padding="0"
      >
        <slot name="body">
          <c-box
            display="flex"
            flex-direction="column"
            align-items="center"
            px="24px"
            py="16px"
            text-align="center"
          >
            <c-text
              font-weight="700"
              :font-size="['18px','20px']"
              mb="16px"
            >
              Program Tidak Dapat Dibeli
            </c-text>
            <c-text
              font-weight="400"
              :font-size="['14px', '16px']"
              color="#555555"
            >
              Kamu masih memiliki program aktif lainnya.  Silahkan menunggu hingga program selesai atau lakukan pembelian dengan jenis program yang sama.
            </c-text>
          </c-box>
        </slot>
      </c-modal-body>
      <c-modal-footer
        v-if="hasFooter"
        padding="0"
      >
        <slot name="footer">
          <c-box
            width="100%"
            px="24px"
            mb="24px"
          >
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              @click="close"
            >
              Kembali
            </BaseButton>
          </c-box>
        </slot>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'BaseModal',
  components: { BaseButton },
  mixins: [mixinsCheckBreakpoints()],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hasBody: {
      type: Boolean,
      default: true,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true,
    },
    withButtonClose: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>